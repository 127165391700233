import React from "react"
import "./../style/toggle.scss"

const Toggle = ({ checked, onChange }) => (
  <span className="toggle-control">

    <input
      className="tgl tgl-skewed"
      id="toggler"
      type="checkbox"
      checked={!checked}
      onChange={onChange}
    />
    { /* eslint-disable */ }
    <label
      className="tgl-btn"
      data-tg-off="Light"
      data-tg-on="Dark"
      htmlFor="toggler"
    />
  </span>
)

export default Toggle
