// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-journey-js": () => import("./../../src/pages/journey.js" /* webpackChunkName: "component---src-pages-journey-js" */),
  "component---src-pages-projects-js": () => import("./../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-tools-js": () => import("./../../src/pages/tools.js" /* webpackChunkName: "component---src-pages-tools-js" */)
}

