import { Link } from "gatsby"
import React from "react"
import HeaderImage from "./headerImage"
import DarkModeToggle from "./darkModeToggle"
import Github from "../images/github.svg"
import Linkedin from "../images/linkedin.svg"
import Netlify from "../images/netlify.svg"
import Stack from "../images/stackoverflow.svg"
import Twitter from "../images/twitter.svg"
import Gatsby from "../images/gatsby.svg"
import "../style/header.scss"

const Header = () => (
  <header className="sidebar">
    <Link to="/">
      <HeaderImage />
    </Link>

    <nav className="nav-bar">
      <Link to="/" activeClassName="active">
        Home
      </Link>

      <Link to="/projects/" activeClassName="active">
        Projects
      </Link>

      <Link to="/journey/" activeClassName="active">
        Journey
      </Link>

      <Link to="/contact/" activeClassName="active">
        Contact
      </Link>
    </nav>

    <DarkModeToggle />

    <footer>
      <div className="socials">
        <a
          href="https://twitter.com/inhaq_"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="twitter"
        >
          <Twitter className="twitter" alt="twitter website" />
        </a>
        <a
          href="https://github.com/inhaq"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="github"
        >
          <Github className="github" alt="github website" />
        </a>
        <a
          href="https://www.linkedin.com/in/inhaq/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="linkedin"
        >
          <Linkedin className="linkedin" alt="linkedin website" />
        </a>
        <a
          href="https://stackoverflow.com/users/6730682/inhaq"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="stack overflow"
        >
          <Stack className="stack" alt="stack overflow website" />
        </a>
      </div>
      <p className="liner">
        <span className="footer-text">Built with</span>{" "}
        <a
          href="https://www.gatsbyjs.org"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="gatsby"
        >
          <Gatsby className="social-icon gatsby" alt="gatsby website" />{" "}
        </a>
        <span className="footer-text">| Served on</span>
        <a
          href="https://www.netlify.com/"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="netlify"
        >
          <Netlify className="social-icon netlify" alt="netlify website" />{" "}
        </a>
      </p>
      <p className="liner">
        <span className="footer-text">
          Authored with{" "}
          <span role="img" aria-label="heart">
            ♥️
          </span>
          ️ by
          <Link to="/" className="my-name">
            {" "}
            @inhaq{" "}
          </Link>
          © {new Date().getFullYear()}
        </span>
      </p>
    </footer>
  </header>
)

export default Header
